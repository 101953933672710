import Grid from '@mui/material/Grid';
import { useState, useContext, useEffect, ReactElement } from 'react';
import { Add as AddIcon, GridView as GridViewIcon } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import JobContext from '../../store/job/job-context';
import { ImportJobPath, ImportJobPathCategory, ImportJobResult, iterationDialogReturnData, JobCreationOptions, JobDetailsBase, JobForm, JobView, SampleJobType, Status, updateJobInfo } from '../../store/job/job-data';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProjectService from '../../services/ProjectsService';
import FileManagementService from '../../services/FileManagementService';
import Iteration from '../../store/project/iteration';
import { Alert, Backdrop, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import IterationsList from '../projects/IterationsList';
import Project from '../../store/project/project';
import IterationDialog from '../projects/IterationDialog';
import ProjectInfoComponent from '../projects/ProjectInfo.component';
import Tooltip from '@mui/material/Tooltip';
import "./styles/JobPage.css";
import { OriginalJob } from '../projects/IterationDialogProps';
import JobService from '../../services/JobService';
import { useJobMutation, useJobsQuery } from './hooks/useJobs';
import theme from '../../styles/main-theme';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { CeetronActionType, CeetronContext, DisplayMode, DisplayPreset, VtfxScalarResults } from '../../store/job/ceetron-context';
import CreditsAccountContext from '../../store/creditsAccount/CreditsAccountContext';
import { saveJobChanges } from './helpers/save-job';
import { TipsAndTricksHelper } from '../../services/TipsAndTricksHelper';
import { UiSettingsContext } from '../../store/uiSettings/UiSettingsContext';
import { ProjectType } from '../../store/project/project-data';
import JobImportResultDialog from "./JobImportResult";
import { useTranslation } from 'react-i18next';
import queryErrorHandler, { successToast } from "../../react-query/queryErrorHandler";
import { arePathsEqual } from './utils/tree.utils';
import MaterialService from '../../services/MaterialService';
import { useMaterials } from '../projects/hooks/useMaterials';

const useStyles = makeStyles({
    mainColumn: {
        position: "relative",
        padding: '1em 1em 1em 1em',
        backgroundColor: theme.palette.background.default,
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    iconAdd: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.secondary.dark,
        "&:hover": {
            backgroundColor: theme.palette.info.dark
        }
    },
    iconClose: {
        padding: "0px",
        fontSize: "1.5em",
        margin: "0.5em",
        cursor: "pointer",
        float: "right",
        backgroundColor: theme.palette.background.default,
    },
    iconCloseRight: {
        padding: "0px",
        fontSize: "1.5em",
        cursor: "pointer",
        float: "left",
        backgroundColor: theme.palette.background.default,
    },
    iconOpen: {
        cursor: 'pointer',
        position: 'absolute',
        marginTop: '-25px',
        float: "right"
    },
    iconOpenRight: {
        cursor: 'pointer',
        position: 'absolute',
        right: '0',
        marginRight: '15px',
        marginTop: '-25px'
    },
    maxJobAlert: {
        color: theme.palette.error.dark, background: theme.palette.secondary.light,
        "& .MuiAlert-icon": {
            color: theme.palette.error.dark
        }
    }
});

export type JobPageProps = {
    content: ReactElement,
    rightControls: ReactElement,
    openBackdrop: boolean,
    backdropProgress?: number,
    reloadJobs?: number,
    onChangeCadModel?: () => void,
    onJobCompareClick?: (iteration: Iteration) => void,
    onJobCancel: (projectId: string, jobId: string) => void
}

export default function JobPage(props: JobPageProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [backdropOpen, setBackdropOpen] = useState(false);
    const jobContext = useContext(JobContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const uiSettingsContext = useContext(UiSettingsContext);
    const { id: projectIdParam, jobId: jobIdParam, container: containerNameParam } = useParams();
    const projectService = new ProjectService();
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [activeProject, setActiveProject] = useState<Project>();
    const [activeIteration, setActiveIteration] = useState<Iteration>();
    const [activeJobDetailsBase, setActiveJobDetailsBase] = useState<JobDetailsBase>();
    const [leftContentCollapsed, setLeftContentCollapsed] = useState(false);
    const [rightContentCollapsed, setRightContentCollapsed] = useState(false);
    const [jobDialogDetails, setJobDialogDetails] = useState<OriginalJob | undefined>();
    const [isEditingJob, setIsEditingJob] = useState<boolean>(false);
    const [iterationDialogTitle, setIterationDialogTitle] = useState<string>("");
    const [iterationDialogButtonLabel, setIterationDialogButtonLabel] = useState<string>("");
    const context = useContext(CeetronContext);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [showCloseLeftPanelIcon, setShowCloseLeftPanelIcon] = useState(false);
    const [showOpenLeftPanelIcon, setShowOpenLeftPanelIcon] = useState(false);
    const [showCloseRightPanelIcon, setShowCloseRightPanelIcon] = useState(false);
    const [showOpenRightPanelIcon, setShowOpenRightPanelIcon] = useState(false);
    const [showImportResultDlg, setShowImportResultDlg] = useState(false);
    const [jobImportErrors, setJobImportErrors] = useState<ImportJobResult[] | undefined>([]);
    const [jobImportWarnings, setJobImportWarnings] = useState<ImportJobResult[] | undefined>([]);
    const [jobImported, setJobImported] = useState<boolean>(false);
    const [jobImportPathCategories, setJobImportPathCategories] = useState<ImportJobPathCategory[] | undefined>([]);
    const [jobImportPathResultCategories, setJobImportPathResultCategories] = useState<ImportJobPathCategory[] | undefined>([]);
    const materialsQuery = useMaterials();

    const fileService = new FileManagementService();

    let jobsQueryData = useJobsQuery(projectIdParam ?? "", containerNameParam!);
    // Resets iterations
    if (activeProject && jobsQueryData) {
        activeProject.iterations = jobsQueryData.data;
    }

    const jobMutation = useJobMutation(projectIdParam ?? "");

    const [jobCreationOptions, setJobCreationOptions] = useState<JobCreationOptions>();
    const creditsAccountContext = useContext(CreditsAccountContext);

    const { openBackdrop } = props;

    const toggleLeftContent = () => {
        //Usually, these would not be needed but the tooltip was showing for a little too long when closing the menu
        //which caused weird visual glitch
        setShowCloseLeftPanelIcon(false)
        setShowOpenLeftPanelIcon(false)

        setLeftContentCollapsed(!leftContentCollapsed);

        const event = new Event('layoutModified');
        document.dispatchEvent(event);
    }

    const toggleRightContent = () => {
        //Usually, these would not be needed but the tooltip was showing for a little too long when closing the menu
        //which caused weird visual glitch
        setShowCloseRightPanelIcon(false)
        setShowOpenRightPanelIcon(false)

        setRightContentCollapsed(!rightContentCollapsed);

        const event = new Event('layoutModified');
        document.dispatchEvent(event);
    }

    const loadProject = async () => {
        try {
            if (!projectIdParam || !containerNameParam) return;

            const project = await projectService.getProject(projectIdParam, containerNameParam);
            if (project) {
                jobContext.setProjectType(project.type);
                setActiveProject(project);
            }
        }
        catch {
            throw new Error('Could not get project');
        }
    };

    const loadData = async () => {
        setIsLoadingData(true);
        setBackdropOpen(true);

        await loadProject();
        await loadActiveJobDetailsBase();
        await loadJobCreationOptions();

        setIsLoadingData(false);
        setBackdropOpen(false);
    }

    const loadJobCreationOptions = async () => {
        const jobCreationOptions = await JobService.getJobCreationConfig();
        setJobCreationOptions(jobCreationOptions);
    }

    const loadActiveJobDetailsBase = async () => {
        if (!projectIdParam || !jobIdParam || !containerNameParam) return;

        const activeJobData = await JobService.getJobDetails(projectIdParam, jobIdParam, containerNameParam);
        if (activeJobData)
            setActiveJobDetailsBase(activeJobData);
    }

    const setActiveJob = () => {
        if (jobsQueryData.data && containerNameParam) {
            let activeIteration = jobsQueryData.data.find((iteration) => iteration.id === jobIdParam);
            setActiveIteration(activeIteration);
            projectIdParam && jobIdParam && projectService.postViewJobEvent(projectIdParam, jobIdParam, containerNameParam);
        }
    }

    useEffect(() => {
        setActiveJob();
    }, [jobsQueryData.dataUpdatedAt]);

    useEffect(() => {
        setBackdropOpen(openBackdrop)
    }, [openBackdrop]);

    useEffect(() => {
        if (activeProject == undefined || jobsQueryData.data == undefined)
            return;

        let projectIsEmpty = Boolean(jobsQueryData.data?.length == 0);
        if (projectIsEmpty)
            addJob();
    }, [activeProject?.id, jobsQueryData.isFetchedAfterMount]);

    useEffect(() => {

        let parameterChange = async () => {
            if (projectIdParam)
                jobsQueryData.refetch();

            //Load initial data
            jobContext.clearContext();
            await loadData();
        }

        parameterChange();

    }, [projectIdParam, jobIdParam]);

    useEffect(() => {
        if (props.reloadJobs == 0) return;

        if (projectIdParam)
            jobsQueryData.refetch();

        loadProject();
    }, [props.reloadJobs]);

    const createJob = async (returnData: iterationDialogReturnData) => {
        if (!containerNameParam) return;

        setBackdropOpen(true);

        jobContext.setTree({});
        jobContext.setIsTreeLoaded(false);

        try {
            const jobData: JobForm = {
                id: undefined,
                projectId: returnData.projectId,
                name: returnData.name,
                description: returnData.description,
                existingCadInfos: returnData.existingCadInfos,
                basedOnJobId: returnData.basedOnJobId,
                containerName: returnData.containerName,
                customSolverSettings: returnData.customSolverSettings
            };

            console.time(`Job creation ${jobData.name}`);

            jobData.id = await JobService.getNewJobId(returnData.projectId, returnData.name);

            if (jobData.id) {

                await Promise.all(returnData.cadFilesToAdd.map(f => fileService.uploadFileToStorage(jobData.projectId, jobData.id!, jobData.containerName, f)));

                await JobService.createJob(jobData, returnData.cadFilesToAdd.map(file => file.name));

                const jobPromise = JobService.checkJobStatus(jobData.projectId, jobData.id, jobData.containerName, 5000);

                const jobInfo: Iteration | undefined = await TipsAndTricksHelper.ShowNextTipsAndTricksWhilePromise<Iteration>(jobPromise, uiSettingsContext.uiSettings.displayTipsAndTricks);

                jobPromise.then(() => console.timeEnd(`Job creation ${jobData.name}`));

                if (jobInfo && containerNameParam) {
                    setActiveIteration(jobInfo);
                    projectService.postViewJobEvent(returnData.projectId, jobInfo.id, containerNameParam);
                    projectService.navigateToIterationSetup(returnData.projectId, jobInfo.id, containerNameParam, navigate, activeProject?.type as ProjectType);
                }
            }


        } catch (error: any) {
            queryErrorHandler(new Error(error.message));
        } finally {
            setBackdropOpen(false);
        }
    }

    const onJobClick = (iteration: Iteration) => {
        if (iteration.id !== activeIteration?.id && activeProject && containerNameParam) {
            setActiveIteration(iteration);
            reinitContextOnJobChange();
            const currentView = location.pathname.indexOf("setup") != -1 ? JobView.Setup : JobView.Result;
            projectService.navigateToIteration(activeProject.id, iteration, containerNameParam, navigate, currentView, activeProject.type as ProjectType);
            projectService.postViewJobEvent(activeProject.id, iteration.id, containerNameParam);
        }
    }

    const reinitContextOnJobChange = () => {
        context.updateCeetronState({ type: CeetronActionType.SetDisplayPreset, payload: DisplayPreset.FREEZE_TIME });
        context.updateCeetronState({ type: CeetronActionType.SetDisplayMode, payload: DisplayMode.CUTTING_PLANE });
        context.updateCeetronState({ type: CeetronActionType.SetScalarResults, payload: VtfxScalarResults.FREEZE_TIME_RESULT });
        context.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: true });
    }

    const editJob = async (returnData: iterationDialogReturnData) => {
        if (!returnData.projectId || !returnData.id || !containerNameParam) return;

        const updateJobInfo: updateJobInfo = {
            projectId: returnData.projectId,
            id: returnData.id,
            name: returnData.name,
            description: returnData.description,
            cadFilesToAdd: returnData.cadFilesToAdd,
            existingCadInfos: returnData.existingCadInfos,
            updateOnCadFiles: returnData.updateOnCadFiles,
            containerName: returnData.containerName,
            customSolverSettings: returnData.customSolverSettings
        }

        setBackdropOpen(true);

        const updatePromise = jobMutation.mutateAsync(updateJobInfo);
        //Show tips and tricks while converting cad files
        await TipsAndTricksHelper.ShowNextTipsAndTricksWhilePromise<updateJobInfo>(updatePromise, uiSettingsContext.uiSettings.displayTipsAndTricks);

        setBackdropOpen(false);

        if (returnData.updateOnCadFiles && returnData.id == activeIteration?.id && activeIteration.solveStatus == Status.None) {
            //reload active iteration base details(cad info)
            await loadActiveJobDetailsBase();

            //cant just reload page because of autosave on old state
            props?.onChangeCadModel?.()
        }
    }

    const setIterationDetails = async (returnData: iterationDialogReturnData, mode?: string) => {
        jobContext.CustomSolverSettings = returnData.customSolverSettings;
        //autosave
        setBackdropOpen(true);
        await saveJobChanges(jobContext, containerNameParam!);
        setBackdropOpen(false);
        if (mode == "edit") {
            await editJob(returnData);
        } else if (mode == "create") {
            await createJob(returnData);
        }
    };

    const onJobDetailsClick = async (iteration: Iteration) => {
        if (!projectIdParam || !jobIdParam || !containerNameParam) return;
        setBackdropOpen(true);
        const jobDetails = await JobService.getJobDetails(projectIdParam, iteration.id, containerNameParam);
        setBackdropOpen(false);
        if (!jobDetails) return;

        const job: OriginalJob = {
            name: iteration.name,
            description: iteration.description,
            jobId: iteration.id,
            thumbnailBlobInfo: iteration.thumbnailBlobInfo,
            cadInfos: jobDetails.CadInfos,
            solveStatus: +iteration.solveStatus,
            containerName: iteration.containerName,
            customSolverSettings: iteration.customSolverSettings
        };

        setIterationDialogButtonLabel("Confirm");
        setIterationDialogTitle("Edit Job");
        setIsEditingJob(true);
        setJobDialogDetails(job);
        setDialogOpen(true);
    }

    const addJob = async () => {
        if (!projectIdParam) return;

        //add job based on current job
        let jobDetails: OriginalJob = {
            name: activeIteration?.name,
            description: activeIteration?.description,
            jobId: activeIteration?.id,
            thumbnailBlobInfo: activeIteration?.thumbnailBlobInfo || null,
            cadInfos: activeJobDetailsBase?.CadInfos,
            containerName: activeIteration?.containerName ?? containerNameParam!,
            customSolverSettings: activeIteration?.customSolverSettings
        }
        setJobDialogDetails(jobDetails);
        setIterationDialogButtonLabel("Add");
        setIterationDialogTitle("Add a new job");
        setIsEditingJob(false);
        setDialogOpen(true);
    }

    const hasReachedMaxJob = () => {
        const maxAccountProjects = getJobLimit();
        if (!jobsQueryData?.data || !maxAccountProjects) return false;
        return jobsQueryData?.data?.length >= maxAccountProjects;
    }

    const getJobLimit = () => {
        if (!jobCreationOptions) return undefined;
        return creditsAccountContext?.isFree ?
            (activeProject?.type == ProjectType.Feasibility ? jobCreationOptions?.freeAccountMaxFeasibilityJobCreation : jobCreationOptions?.freeAccountMaxJobCreation)
            : (activeProject?.type == ProjectType.Feasibility ? jobCreationOptions?.paidAccountMaxFeasibilityJobCreation : jobCreationOptions?.paidAccountMaxJobCreation);
    }

    const onJobCancel = (projectId: string, jobId: string) => {
        if (projectIdParam) {
            jobsQueryData.refetch();

            loadProject();
        }

        props.onJobCancel(projectId, jobId)
    }

    useEffect(() => {
        if (jobContext.IsTreeLoaded && jobImportPathCategories?.length) {
            let cats: ImportJobPathCategory[] = []
            let mapped: boolean = false;

            jobImportPathCategories?.map((cat) => {
                let jobParts: ImportJobPath[] = [];
                cat?.paths?.map((path) => {
                    let values: string[] = [];
                    path?.values?.map((val) => {
                        Object.values(jobContext.Tree).forEach(treeItem => {
                            if (arePathsEqual(treeItem.path, val)) {
                                mapped = true;
                                return;
                            }
                        });
                        if (!mapped) {
                            values.push(val)
                        }
                        mapped = false;
                    });
                    if (values?.length > 0) {
                        let importJobPath: ImportJobPath = { name: path.name, values: values };
                        jobParts.push(importJobPath);
                    }
                });
                if (jobParts?.length > 0) {
                    cats.push({ category: cat?.category, paths: jobParts });
                }
            });
            setBackdropOpen(false);
            setJobImportPathResultCategories(cats);
            setJobImportPathCategories([]);
            if (cats.length > 0) {
                setShowImportResultDlg(true);
            } else {
                successToast(t("Job has been successfully imported."));
            }
        }
    }, [jobContext.IsTreeLoaded, jobImportPathCategories]);


    const onSelectFileImportJob = async (event: any, newJobName: string, file: File, description: string) => {
        try {
            setBackdropOpen(true);

            const materialsJson = JSON.stringify(materialsQuery.data?.data ?? {});
            const materialsBlob = new Blob([materialsJson], { type: 'application/json' });
            const materialsFile = new File([materialsBlob], 'available-materials.json');
            const newJobId = await JobService.getNewJobId(projectIdParam!!, newJobName);

            await fileService.uploadFileToStorage(projectIdParam!!, newJobId, containerNameParam!, materialsFile);

            await fileService.uploadFileToStorage(projectIdParam!!, newJobId, containerNameParam!, file);

            await JobService.submitImportRequest({
                projectId: projectIdParam!!, 
                jobId: newJobId, 
                containerName: containerNameParam!, 
                description, 
                filename: file.name,
                jobName: newJobName,
            });

            var data = await JobService.checkImportStatus(projectIdParam!!, newJobId, containerNameParam!, 5000);

            await JobService.checkJobStatus(projectIdParam!!, newJobId, containerNameParam!, 5000);

            jobContext.setTree({});
            jobContext.setIsTreeLoaded(false);
            setJobImportErrors(data.errors);
            setJobImportWarnings(data.warnings);
            setJobImported(data.imported);
            setJobImportPathCategories(data.partCategories);

            // critical errors displays immediately
            if (!data.imported || !data.partCategories || data.partCategories.length == 0) {
                setShowImportResultDlg(true);
                setBackdropOpen(false);
            } else if ((!data.partCategories || data.partCategories.length == 0) &&
                (!data.errors || data.errors.length == 0) && (!data.warnings || data.warnings.length == 0)) {
                successToast(t("Job has been successfully imported."));
                setBackdropOpen(false);
            }

            // attempt to setup active job
            if (data.imported && activeProject?.id) {
                projectService.postViewJobEvent(activeProject?.id, data.jobId, containerNameParam!);
                projectService.navigateToIterationSetup(activeProject?.id, data.jobId, containerNameParam!, navigate, activeProject?.type as ProjectType);
            }

        } catch (error: any) {
            queryErrorHandler(new Error(error.message));
        }
    }

    const onCreateJobWithSampleFile = async (sampleJobType: SampleJobType) => {
        setBackdropOpen(true);
        setDialogOpen(false);
        
        try {
            if (projectIdParam == undefined || containerNameParam == undefined) return;

            const jobWithSampleFile = await JobService.createJobWithSampleFile(projectIdParam, containerNameParam, sampleJobType);

            if (jobWithSampleFile) {
                projectService.navigateToIterationSetup(jobWithSampleFile.projectId, jobWithSampleFile.id, containerNameParam, navigate, activeProject?.type as ProjectType);
            }
        } catch (error: any) {
            queryErrorHandler(new Error(error.message));
        } finally {
            setBackdropOpen(false);
        }
    }

    const onCloseJobImportResultDialog = () => {
        setShowImportResultDlg(false);
        setJobImportErrors([]);
        setJobImportPathResultCategories([]);
        setJobImportWarnings([]);
    }

    return (
        <>
            <Backdrop open={backdropOpen || (props.backdropProgress !== undefined && props.backdropProgress < 100)}
                sx={{ color: (theme) => theme.palette.secondary.light, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" value={props.backdropProgress} />
            </Backdrop>
            <JobImportResultDialog
                open={showImportResultDlg}
                onClose={() => onCloseJobImportResultDialog()}
                errors={jobImportErrors}
                pathResultCategories={jobImportPathResultCategories}
                jobImported={jobImported}
                warnings={jobImportWarnings}
            />
            <IterationDialog
                originalJob={jobDialogDetails}
                projectId={projectIdParam!!}
                projectType={activeProject?.type}
                open={dialogOpen || false}
                buttonText={t(iterationDialogButtonLabel)}
                title={t(iterationDialogTitle)}
                edit={isEditingJob}
                OkHandler={() => { setDialogOpen(false); setIsEditingJob(false) }}
                CancelHandler={() => { setDialogOpen(false); setIsEditingJob(false) }}
                setDetails={setIterationDetails}
                onSelectFileImportJob={onSelectFileImportJob}
                onCreateJobWithSampleFile={onCreateJobWithSampleFile}
                showCreateJobWithSampleFile={creditsAccountContext?.isFree}
            />
            {!isLoadingData &&
                <Grid container sx={{ position: 'relative', display: 'flex', flexWrap: 'nowrap', backgroundColor: theme.palette.background.default, height: '100%' }}>
                    <Box id="jobViewerContainer" sx={{ order: 2, flex: '1', minWidth: '0', position: 'relative', backgroundColor: theme.palette.background.default }} className={classes.mainColumn} >
                        {props.content}
                        <Box >
                            <Tooltip
                                title={t("Open side panel")}
                                leaveDelay={0}
                                TransitionProps={{ timeout: 0 }}
                                open={showOpenLeftPanelIcon}
                                onMouseEnter={() => setShowOpenLeftPanelIcon(true)}
                                onMouseLeave={() => setShowOpenLeftPanelIcon(false)} >
                                <KeyboardDoubleArrowRightIcon onClick={toggleLeftContent}
                                    sx={{ display: leftContentCollapsed == false ? 'none' : 'initial' }}
                                    className={classes.iconOpen}></KeyboardDoubleArrowRightIcon>
                            </Tooltip>
                        </Box>
                        <Box>
                            <Tooltip
                                title={t("Open side panel")}
                                leaveDelay={0}
                                TransitionProps={{ timeout: 0 }}
                                open={showOpenRightPanelIcon}
                                onMouseEnter={() => setShowOpenRightPanelIcon(true)}
                                onMouseLeave={() => setShowOpenRightPanelIcon(false)} >
                                <KeyboardDoubleArrowLeftIcon onClick={toggleRightContent}
                                    sx={{ display: rightContentCollapsed == false ? 'none' : 'initial' }}
                                    className={classes.iconOpenRight}></KeyboardDoubleArrowLeftIcon>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box className={'rightPageContent ' + (rightContentCollapsed == true ? 'collapsed' : 'open')}
                        sx={{
                            display: "flex",
                            order: 3,
                            flexDirection: "column",
                            padding: "1em 0.6em 1em 0.6em",
                            borderRadius: "2em",
                            maxHeight: "100%",
                            backgroundColor: theme.palette.background.default
                        }} >
                        {props.rightControls}
                        {!isLoadingData && <Grid item sx={{ p: 1, backgroundColor: theme.palette.background.default, padding: '0', marginBottom: '-5px' }}>
                            <Box className={classes.iconCloseRight} >
                                <Tooltip
                                    leaveDelay={0}
                                    title={t("Close side panel")}
                                    TransitionProps={{ timeout: 0 }}
                                    open={showCloseRightPanelIcon}
                                    onMouseEnter={() => setShowCloseRightPanelIcon(true)}
                                    onMouseLeave={() => setShowCloseRightPanelIcon(false)}>
                                    <KeyboardDoubleArrowRightIcon onClick={toggleRightContent} sx={{ cursor: 'pointer' }}></KeyboardDoubleArrowRightIcon>
                                </Tooltip>
                            </Box>
                        </Grid>}
                    </Box>
                    <Box className={'leftPageContent ' + (leftContentCollapsed == true ? 'collapsed' : 'open')} sx={{ order: 1, height: '100%' }} >
                        <Grid container wrap='nowrap' direction='column' sx={{ height: '100%' }}>
                            <Grid item sx={{ p: 1, display: 'block', justifyContent: 'space-between', alignItems: 'center', width: 'auto', marginBottom: '-10px' }} >
                                <Box sx={{ display: 'flex', paddingRight: '20px' }} color={theme.palette.secondary.dark}>
                                    {activeProject && <ProjectInfoComponent
                                        project={activeProject}
                                        compact={true}
                                        key={activeProject.id}
                                    />}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ overflow: 'hidden', paddingRight: '0!important', backgroundColor: theme.palette.background.default, p: 1 }}>
                                <Grid container wrap='nowrap' direction='column' sx={{ height: '100%' }}>
                                    <Grid item>
                                        <Grid container direction='row' sx={{ justifyContent: 'space-between', padding: "8px", paddingBottom: "2px", alignItems: "center", marginTop: '-13px' }}>
                                            <Grid item><Typography variant={'h6'} color={theme.palette.primary.dark} display="inline">{t("Jobs")}</Typography></Grid>
                                            <Grid item>
                                                {hasReachedMaxJob() && <Alert severity="info" className={classes.maxJobAlert}
                                                    sx={{ mb: "0" }}>{t("Job limit reached")} ({getJobLimit()})</Alert>}
                                                {!hasReachedMaxJob() && <Button id="add-job-button" className={classes.iconAdd} variant={'contained'} onClick={() => (addJob())}>
                                                    <AddIcon />
                                                </Button>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={{ overflowX: 'auto' }}>
                                        <Box >
                                            {activeProject && <IterationsList
                                                iterations={jobsQueryData.data || []}
                                                activeIteration={activeIteration?.id}
                                                compact={true}
                                                onJobDetailsClick={onJobDetailsClick}
                                                onJobCompareClick={props.onJobCompareClick}
                                                onJobClick={onJobClick}
                                                onJobCancel={onJobCancel}
                                            />}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ p: 1, backgroundColor: theme.palette.background.default, padding: '0' }}>
                                <Box className={classes.iconClose} >
                                    <Tooltip
                                        leaveDelay={0}
                                        title={t("Close side panel")}
                                        TransitionProps={{ timeout: 0 }}
                                        open={showCloseLeftPanelIcon}
                                        onMouseEnter={() => setShowCloseLeftPanelIcon(true)}
                                        onMouseLeave={() => setShowCloseLeftPanelIcon(false)}>
                                        <KeyboardDoubleArrowLeftIcon onClick={toggleLeftContent} sx={{ cursor: 'pointer' }}></KeyboardDoubleArrowLeftIcon>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            }
        </>
    )
}