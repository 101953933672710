import Grid from '@mui/material/Grid';
import { useCallback, useState, useContext, useEffect, useRef, useReducer } from 'react';
import GeometryViewer from './GeometryViewer';
import makeStyles from '@mui/styles/makeStyles';
import TreeviewStructure from './TreeviewStructure';
import MoldCategoryDisplay from './MoldCategoryDisplay';
import JobContext from '../../store/job/job-context';
import MaterialSelector from './MaterialSelector';
import { MoldCategory, MoldMaterialCategory } from '../../store/job/mold-categories';
import JobData, { BasicMold, CategoriesData, ChannelBodyType, MaterialRootMap, Status } from '../../store/job/job-data';
import CoolingCategoryDisplay from './CoolingCategoryDisplay';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import ProjectService from '../../services/ProjectsService';
import MaterialService from '../../services/MaterialService';
import JobProjectSwitcher from './JobProjectSwitcher';
import JobPage from './JobPage';
import JobService from '../../services/JobService';
import IterationInfoPanel from './IterationInfoPanel';
import Iteration from '../../store/project/iteration';
import { Alert, Box, Button, Tooltip, FormControlLabel, Switch, useTheme, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import MoldMaterial from '../../store/job/mold-material';
import RangeMaterial, { defaultPartRangeMaterial, defaultMoldRangeMaterial } from '../../store/job/range-material';
import { MaterialType } from './CustomMaterialDialog';
import AddBoxIcon from '@mui/icons-material/AddBox';
import UpcomingFeatureDialog from '../common/UpcomingFeatureDialog';
import { mapChannels, mapParts, mapMolds } from './helpers/tree-mapper';
import BasicMoldBlockDisplay from './BasicMoldBlockDisplay';
import { HoopsContext } from '../../store/job/hoops-context';
import MaterialDetailDialog from './MaterialDetailDialog';
import { saveJobChanges } from './helpers/save-job';
import { useTranslation } from 'react-i18next';
import { ProjectType } from '../../store/project/project-data';
import useIsMounted from '../../hooks/useIsMounted';
import { PlasticPartMaterialSelector } from './PlasticPartMaterialSelector';
import { ExtraJobConfig, ExtraJobConfiguration } from './ExtraJobConfiguration';
import AuthContext from '../../auth/AuthenticationContext';
import {enableBasicMold, disableBasicMold, handleBasicMoldSizeChange} from './helpers/Basic-mold';

export default function JobCreationPage() {
    const { t } = useTranslation();
    const [hoopsWebViewer, setHWV] = useState<Communicator.WebViewer | null>(null);
    const [isStructureReady, setIsStructureReady] = useState(false);
    const jobContext = useContext(JobContext);
    const authContext = useContext(AuthContext);
    const theme = useTheme();
    const isMounted = useIsMounted();

    const useStyles = makeStyles({
        stack: {
            position: 'relative',
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            flex: "1 1 0px"
        },
        gridSubContainer: {
            width: "100%",
            flex: "1 1 0px",
            flexDirection: "column",
            flexWrap: "nowrap",
            overflowY: "auto",
            padding: "5px 7px 5px 5px",
            scrollbarColor: theme.palette.secondary.dark
        },
        gridSubContainerHeader: {
            width: "100%",
            borderRadius: "2em",
            marginBottom: "0.6em",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",

        },
        gridItem: {
            width: "100%",
            borderRadius: "2em",
            marginBottom: "0.6em"
        },
        iconStyle: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",

            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        },
        selectIconMaterial: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "block",
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.text.disabled,
                    color: theme.palette.text.disabled,
                    borderRadius: "2em",
                    marginRight: '10px',
                    margin: '0',
                    fontSize: '1rem',
                    lineHeight: '1.8em',
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: '400',
                    textTransform: 'none',
                    width: "220px",
                    "&:hover": {
                        backgroundColor: theme.palette.background.paper,
                        borderColor: theme.palette.text.disabled,
                    }
                },
            },
        },
        formMaterial: {
            maxWidth: "250px",
            fontSize: '1rem',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: '400',
            textTransform: 'none',
            '& .MuiIconButton-root': {
                '& fieldset': {
                    padding: '1px'
                }
            },
            '& .MuiInputLabel-root': {
                left: '10px'
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: "2em",
                    borderColor: theme.palette.text.disabled,
                    color: theme.palette.text.disabled,
                    padding: '17px 0px',
                    margin: '5px'
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.text.disabled,
                }
            }
        }
    });

    const classes = useStyles();
    const { id: projecIdParam, jobId: jobIdParam, container: containerNameParam} = useParams();
    const projectService = new ProjectService();
    const [partMaterials, setPartMaterials] = useState<MoldMaterial[]>([]);
    const [moldMaterials, setMoldMaterials] = useState<MoldMaterial[]>([]);
    const [moldRangeMaterial, setMoldRangeMaterial] = useState<RangeMaterial>(defaultMoldRangeMaterial);
    const [partRangeMaterial, setPartRangeMaterial] = useState<RangeMaterial>(defaultPartRangeMaterial);
    const [takeSnapshot, setTakeSnapshot] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [reloadJobs, setReloadJobs] = useState(0);
    const [reloadTreeStructure, setReloadTreeStructure] = useState(0);
    const [iteration, setIteration] = useState<Iteration | null>(null);
    const [disableSideControl, setDisableSideControl] = useState<boolean>(false);
    const [inletOutletSelectionMode, setInletOutletSelectionMode] = useState(false);
    const [openUpcomingFeatureDialog, setOpenUpcomingFeatureDialog] = useState(false);
    const [openUpcomingFeatureDialogName, setOpenUpcomingFeatureDialogName] = useState("");
    const [feasibilityProject, setFeasibilityProject] = useState<boolean>(false);

    const initialLoadingState = {
        basicMold: false,
        molds: false,
        part: false
    }

    const loadingReducer = (state: any, value: any) => {
        if (value.type.toLowerCase() == "molds") {
            return {
                ...state,
                molds: value.isLoading
            }
        } else if (value.type.toLowerCase() == "part") {
            return {
                ...state,
                part: value.isLoading
            }
        } else if (value.type.toLowerCase() == "basicmold") {
            return {
                ...state,
                basicMold: value.isLoading
            }
        }

        return state;
    }
    const [loading, dispatchLoading] = useReducer(loadingReducer, initialLoadingState);

    const OnHwvReady = useCallback((hwv: Communicator.WebViewer) => {
        setIsStructureReady(true);
        setHWV(hwv);
    }, []);

    ///
    /// When declaring of a callback function, such as the one below, a closure effect occurs
    /// where the callback function takes a copy of the variables. Therefore, within those
    /// types of function to access the current value of a variable is done via a reference.
    ///
    const jobContextRef = useRef<JobData>();
    jobContextRef.current = jobContext;

    //Unmount hoops viewer on page change
    useEffect(() => {
        return () => {
            const mounted = isMounted();
            if (!mounted) {
                shutdownHoopsViewer();
            }
        }
    }, [hoopsWebViewer, isMounted])

    //save at unmount (refresh, page change)
    useEffect(() => {
        const handleRefresh = () => {
            saveJobChanges(jobContextRef.current!, containerNameParam!);
        };

        window.addEventListener('beforeunload', handleRefresh);

        return () => {
            window.removeEventListener('beforeunload', handleRefresh);
        }
    }, []);

    useEffect(() => {
        if (!isStructureReady || jobContext.contextCleaned == false || !projecIdParam || !jobIdParam || !containerNameParam) return;

        JobService.isThumbnailExists(projecIdParam, jobIdParam, containerNameParam).then((isThumbnailExists) => {
            if (!isThumbnailExists) {
                takeSnaphot();
            }
        })

    }, [isStructureReady, jobContext.contextCleaned]);

    useEffect(() => {
        setFeasibilityProject(jobContext?.ProjectType == ProjectType.Feasibility);
    }, [jobContext?.ProjectType]);

    useEffect(() => {
        if (projecIdParam && jobIdParam && hoopsWebViewer && isStructureReady && jobContext.contextCleaned && containerNameParam) {
            projectService.getIteration(projecIdParam!, jobIdParam!, containerNameParam)
                .then((it) => {
                    setIteration(it);
                    jobContext.setStatus(it.solveStatus);
                });
            !feasibilityProject && jobContext.basicMold.enabled && enableBasicMold(jobContext, hoopsWebViewer);
        }
    }, [hoopsWebViewer, isStructureReady, jobContext.contextCleaned]);

    useEffect(() => {
        if (!isMounted() || !isStructureReady || !hoopsWebViewer || jobContext.contextCleaned == false || !hoopsWebViewer.model.getAbsoluteRootNode()) return;

        if (!feasibilityProject) {
            // Reset opacity of all nodes except the ones for the basic mold
            hoopsWebViewer.model.resetModelOpacity();
            try {
                hoopsWebViewer.model.setNodesOpacity(jobContext.basicMold.nodesIds, 0.5); // TODO: investigate why sometimes nodeIds may not exist anymore
            } catch {
                console.debug('basic mold opacity could not be set, node ids may already have been discarded');
            }
        }
        const nodeFromSelectedChannel = jobContext.Categories.SelectedChannel?.getBodyParts()?.flatMap(b => b.nodesIds);

        nodeFromSelectedChannel && !inletOutletSelectionMode && hoopsWebViewer.model.setNodesOpacity(nodeFromSelectedChannel, 1);
    }, [hoopsWebViewer, jobContext.contextCleaned, jobContext.Categories, JSON.stringify(jobContext.Categories.Channel), inletOutletSelectionMode]);

    //Cleaning context, removing parts that are not in this CAD 
    useEffect(() => {
        //If context already cleaned, dont do it again
        //Wait for tree to be populated
        if (jobContext.contextCleaned == true || !jobContext.Project || !jobContext.JobId || Object.keys(jobContext.Tree).length === 0) {
            return;
        }

        jobContext.setCategoryParts(MoldCategory.Part, mapParts(jobContext.Categories.Part, jobContext.Tree));
        jobContext.setCategoryParts(MoldCategory.Mold, mapMolds(jobContext.Categories.Mold, jobContext.Tree));
        jobContext.setCategoryParts(MoldCategory.Runner, mapParts(jobContext.Categories.Runner ?? [], jobContext.Tree));
        jobContext.setCategoryParts(MoldCategory.Channel, mapChannels(jobContext.Categories.Channel, jobContext.Tree));
        jobContext.SetContextCleaned(true);
    }, [jobContext.Tree, jobContext.Project, jobContext.JobId]);

    const resetState = () => {
        setInletOutletSelectionMode(false);
        jobContext.clearContext();
    }

    const shutdownHoopsViewer = () => {
        if (hoopsWebViewer) {
            setHWV(null);
            hoopsWebViewer?.shutdown();
        }
    }

    useEffect(() => {
        loadDataOnParamChange();
    }, [projecIdParam, jobIdParam]);


    const setSideControlState = (iterationSolveState: number) => {
        setDisableSideControl(iterationSolveState != 0);
    }

    const loadDataOnParamChange = async () => {
        resetState();
        setBackdropOpen(true);
        await saveJobChanges(jobContext, containerNameParam!);
        shutdownHoopsViewer();
        await loadIterationParameters();
        setBackdropOpen(false);
    }

    const loadIterationParameters = async () => {

        if (!projecIdParam || !jobIdParam || !containerNameParam) {
            return;
        }

        let jobParameters;
        let status: Status = Status.None;

        try {
            jobParameters = await JobService.getJobDetails(projecIdParam, jobIdParam, containerNameParam);
            const iteration = await projectService.getIteration(projecIdParam, jobIdParam, containerNameParam);
            setIteration(iteration);

            setSideControlState(iteration.solveStatus);

            status = iteration.solveStatus;
        } catch (e) {
            console.debug('error recovering job parameters')
            return;
        }

        if (jobParameters) {
            const categories = Object.assign(new CategoriesData(), jobParameters.Categories);
            jobContext.restoreContext(projecIdParam, jobIdParam, categories, jobParameters.CadInfos, status, jobParameters.expectedCoolingTime, jobParameters.CustomSolverSettings, jobParameters.basicMold);
        }
    };

    useEffect(() => {
        if (!jobContext.contextCleaned || !jobContext.resizeBasicMold || !jobContext.basicMold.enabled) return;
        if (hoopsWebViewer) {
            handleBasicMoldSizeChange(jobContext, hoopsWebViewer);
        }
        jobContext.setResizeBasicMold(false);
    }, [jobContext.contextCleaned, jobContext.resizeBasicMold])

    const takeSnaphot = () => {
        setTakeSnapshot(true);
    }

    const onGeneratedSnapshot = async (imgBlob: Blob) => {
        window.console.log("snapshot generated")
        setBackdropOpen(false);
        setTakeSnapshot(false);
        if (projecIdParam && jobIdParam && containerNameParam) {
            await JobService.saveThumbnail(imgBlob, projecIdParam, jobIdParam, containerNameParam);
            reloadSideJobs();
        }
    }

    const onGeneratingSnapshot = () => {
        window.console.log("generating snapshot")
        setBackdropOpen(true);
    }

    const onJobSubmit = () => {
        window.console.log("on job submit")
        reloadSideJobs();
        takeSnaphot();
        setDisableSideControl(true);
    }

    const onChangeCadModel = async () => {
        await reloadJob();
    }

    const OnModelSwitch = () => {
        setReloadTreeStructure(reloadTreeStructure + 1);
    }

    const toggleInletOutletSelectionMode = (isInletOutletSelectionMode: boolean) => {
        setInletOutletSelectionMode(isInletOutletSelectionMode);
    }

    const openComingSoonDialog = (featureName: string) => {
        setOpenUpcomingFeatureDialogName(featureName);
        setOpenUpcomingFeatureDialog(true);
    }

    useEffect(() => {
        if (!isStructureReady) return;
        if (!feasibilityProject && jobContext.basicMold.enabled && hoopsWebViewer) {
            handleBasicMoldSizeChange(jobContext, hoopsWebViewer);
        }
    }, [jobContext.basicMold.size]);    

    const reloadJob = async () => {
        setBackdropOpen(true);
        resetState();
        await loadIterationParameters();
        setBackdropOpen(false);
    }

    const onJobCancel = async () => {
        //reset iteration.json to current.json
        if (projecIdParam && jobIdParam) {
            await reloadJob();
            reloadSideJobs();
        }
    }

    const onSideListJobCancel = async (projectId: string, jobId: string) => {
        if (projectId == projecIdParam && jobId == jobIdParam) {
            await reloadJob();
        }
    }

    const reloadSideJobs = () => {
        setReloadJobs(reloadJobs + 1);
    }

    const addMoldGroup = () => {
        let moldGrpName = "Mold Group " + (jobContext.Categories.Mold.length + 1);
        jobContext.addToCategory(MoldCategory.Mold, jobContext.Categories.Selected, jobContext.Categories.Mold.length.toString(), moldGrpName);
    };

    const moldMaterial = (moldGroupId: string) => {
        const index = jobContext.Categories.Materials.Molds.findIndex(material => material.moldGroupId === moldGroupId);
        if (index !== -1) {
            return jobContext.Categories.Materials.Molds[index];
        } else {
            return moldMaterials[0];
        }
    }

    const extraJobConfig = {
        expectedCoolingTime: jobContext.expectedCoolingTime
    };

    const onChangeConfiguration = (data: ExtraJobConfig) => {
        jobContext.setExtraJobConfiguration(data);
    }
    
    return <HoopsContext hwv={hoopsWebViewer} jobContext={jobContext}>
        <JobPage
            reloadJobs={reloadJobs}
            onChangeCadModel={onChangeCadModel}
            openBackdrop={backdropOpen}
            onJobCancel={onSideListJobCancel}
            content={<Grid container direction='column' sx={{ height: '100%', position: "relative" }}>
                <JobProjectSwitcher
                    onJobSubmit={onJobSubmit}
                    selected='setup'
                    project={projecIdParam!}
                    projectType={jobContext.ProjectType as ProjectType}
                    job={iteration}
                    jobId={jobIdParam}
                    hwv={hoopsWebViewer}
                    backdropFunc={null}
                    onJobCancel={onJobCancel} />
                {iteration &&
                    <Grid item sx={{ position: "absolute", width: "100%", top: "5em" }}>
                        <IterationInfoPanel iteration={iteration} />
                    </Grid>
                }
                {isStructureReady && <TreeviewStructure Hwv={hoopsWebViewer!} reloadTreeStructure={reloadTreeStructure} />}
                <Box style={{ width: '100%', maxHeight: "100%", display: 'flex', flexDirection: 'row', flex: "1 auto" }}>
                    <GeometryViewer
                        takeSnapshotProp={takeSnapshot}
                        OnGeneratingSnapshot={onGeneratingSnapshot}
                        OnGeneratedSnapshot={onGeneratedSnapshot}
                        OnHwvReady={OnHwvReady}
                        GeometryFiles={jobContext.CadInfos}
                        OnModelSwitch={OnModelSwitch}
                        OnModelLoading={() => { setBackdropOpen(true); }}
                        OnModelLoaded={() => {
                            setBackdropOpen(false);
                            jobContext.setSelectionMode(ChannelBodyType.PARTS);
                        }} />
                </Box>
            </Grid>}
            rightControls={< Stack className={classes.stack} >
                <Grid container className={classes.gridSubContainer} >
                    <Grid id="moldGroupSection" container className={classes.gridSubContainerHeader} >
                        {!feasibilityProject && !disableSideControl && <Button
                            id="add-mold-group-button"
                            onClick={() => { addMoldGroup() }}
                            className={classes.iconStyle}
                            startIcon={<AddBoxIcon />} size='small' variant='contained'>
                            {t("Mold Group")}
                        </Button>
                        }
                    </Grid>
                    <Grid item className={classes.gridSubContainerHeader}>
                        {disableSideControl &&
                            <Alert id="cannotModifyJobAfterSubmitAlert" severity="info" sx={{ mb: "10px" }}>{t("This job has been submitted and cannot be modified. Please create another one.")}</Alert>
                        }
                    </Grid>
                    <Grid item className={classes.gridSubContainerHeader}>
                        {!feasibilityProject && <FormControlLabel
                            id="basic-mold-toggle"
                            control={
                                <Switch color="primary" sx={{ ml: "10px" }}
                                    disabled={disableSideControl}
                                    onClick={() => { hoopsWebViewer ? (jobContext.basicMold.enabled ? disableBasicMold(jobContext, hoopsWebViewer) : enableBasicMold(jobContext, hoopsWebViewer)) : ""}}
                                    checked={jobContext.basicMold.enabled} />
                            }
                            label={t("Use Basic Mold")}
                        />}
                    </Grid>
                    {!feasibilityProject && !jobContext.basicMold.enabled && <>
                        {jobContext.Categories.Mold.map((moldGroup, i) =>
                            <div key={`${t("Mold Group")}-${i}`}>
                                <Grid item className={classes.gridItem}>
                                    <MoldCategoryDisplay moldGroupId={moldGroup.id ? moldGroup.id : i.toString()}
                                        moldGroupName={`${t("Mold Group")} ${moldGroup.name ? moldGroup.name.replace(/[^\d\.]*/g, '') : i + 1}`}
                                        category={MoldCategory.Mold}
                                        title={`${t("Mold Group")} ${moldGroup.name ? moldGroup.name.replace(/[^\d\.]*/g, '') : i + 1}`}
                                        parts={moldGroup.bodies} isLoading={loading.mold} disabled={disableSideControl}>
                                        {!disableSideControl && 
                                        <MaterialSelector
                                            categoryName={MoldMaterialCategory.Molds}
                                            moldGroupId={i.toString()}
                                            moldGroupName={`${t("Mold")}-${i}`}
                                            rangeMaterial={moldRangeMaterial}
                                            hideTemperatureInput={true}
                                            disabled={disableSideControl} />}
                                        {disableSideControl && jobContext.Categories.Materials.Molds &&
                                            <Tooltip placement="left" title={moldMaterial(moldGroup.id)?.name ?? ""}>
                                                <FormControl disabled variant="outlined" className={classes.formMaterial}  >
                                                    <InputLabel htmlFor="outlined-adornment-password" sx={{ width: '75%' }}>{moldMaterial(moldGroup.id)?.name}</InputLabel>

                                                    <OutlinedInput
                                                        type='text'
                                                        endAdornment={
                                                            <InputAdornment position="end" sx={{ marginright: '10px' }} >
                                                                <MaterialDetailDialog type={MaterialType.Mold} material={moldMaterial(moldGroup.id)} />
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </Tooltip>
                                        }
                                    </MoldCategoryDisplay>
                                </Grid>
                            </div>
                        )}
                    </>}
                    {(jobContext.basicMold.enabled || feasibilityProject) && <><Grid item className={classes.gridItem}>
                        <BasicMoldBlockDisplay isLoading={loading.mold} disabled={disableSideControl}>
                            {!disableSideControl && <MaterialSelector
                                categoryName={MoldMaterialCategory.BasicMold}
                                moldGroupId={'0'}
                                moldGroupName={"BasicMold"}
                                rangeMaterial={moldRangeMaterial}
                                hideTemperatureInput={true}
                                disabled={disableSideControl}
                            />}
                            {disableSideControl && jobContext.Categories.Materials.BasicMold &&
                                <Tooltip placement="left" title={jobContext.Categories.Materials.BasicMold[0]?.name}>
                                    <FormControl disabled variant="outlined" className={classes.formMaterial}  >
                                        <InputLabel htmlFor="outlined-adornment-password" sx={{ width: '75%' }}>{jobContext.Categories.Materials.BasicMold[0]?.name}</InputLabel>
                                        <OutlinedInput
                                            type='text'
                                            endAdornment={
                                                <InputAdornment position="end" sx={{ marginright: '10px' }} >
                                                    <MaterialDetailDialog type={MaterialType.Mold} material={jobContext.Categories.Materials.BasicMold[0]} />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Tooltip>
                            }
                        </BasicMoldBlockDisplay>
                    </Grid></>}
                    <Grid item className={classes.gridItem}>
                        <MoldCategoryDisplay
                            moldGroupId={"0"}
                            category={MoldCategory.Part}
                            title={t("Plastic Part")}
                            parts={jobContext.Categories.Part}
                            secondarySectionCategory={MoldCategory.Runner}
                            secondarySectionTitle={t("Runners")}
                            secondarySectionParts={jobContext.Categories.Runner}
                            secondarySectionConfigButton={
                                <Button disabled={disableSideControl}
                                    onClick={() => { openComingSoonDialog(t("Hot Runner Support")) }}
                                    className={classes.iconStyle}
                                    startIcon={<AddBoxIcon />} size='small' variant='contained'>
                                    {t("Configure Hot Runners")}
                                </Button>
                            }
                            isLoading={loading.part}
                            disabled={disableSideControl}>
                            <PlasticPartMaterialSelector
                                disableSideControl={disableSideControl}
                                rangeMaterials={partRangeMaterial} />
                        </MoldCategoryDisplay>
                    </Grid>
                    {jobContext.ProjectType !== 'Feasibility' && <Grid item className={classes.gridItem}>
                        <CoolingCategoryDisplay disabled={disableSideControl} iterationStatus={iteration ? iteration.solveStatus : 0} title={t("Channels")}
                            channels={jobContext.Categories.Channel} cooling={jobContext.Categories.Cooling} Hwv={hoopsWebViewer!} onToggleInletOutletSelectionMode={toggleInletOutletSelectionMode} />
                    </Grid>}
                    {jobContext.ProjectType !== 'Feasibility' &&
                        <Grid item className={classes.gridItem}>
                            <ExtraJobConfiguration 
                                disabled={disableSideControl}
                                extraConfig={extraJobConfig} 
                                onChangeConfiguration={onChangeConfiguration}/>
                        </Grid>
                    }                    
                </Grid>
            </Stack>}
        />
        <UpcomingFeatureDialog
            upcomingFeature={openUpcomingFeatureDialogName}
            onClose={() => { setOpenUpcomingFeatureDialog(false) }}
            open={openUpcomingFeatureDialog} />
    </HoopsContext>
}
